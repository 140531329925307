import * as web3 from '@solana/web3.js';

export const FIRST_CREATOR_IS_LAST_INDEX = false ;//false; // change to false for mainnet as it's the first index 
                                                // instead of last


export const NETWORK = "mainnet-beta";

export const programId : web3.PublicKey = 

new web3.PublicKey("FRkE4GbyZsbvrPJKg8nf34QivBZhYND6gwk5AERBskLp");

// for $DAWG token 
export const DC_TOKEN_DECIMAL = 9; 

export const DC_TOKEN_MINT = "BvEj2MNMPsUrD4vSk7NHs4TtRcCcJd75Wx5HvVbY4rbK";

//"9QKHcZihXMdBgYdrWgqXCP6o6GWWb9ttsqS7WXSVuJZ8";

// This is the wallet address that created the $DAWG token vault
export const DC_TOKEN_VAULT_OWNER = "5P31hvWD2VTznjKSekq2YuYuCk5hkA6mkhyLRiXJ6keT";


// NFT token vault API URI
export const NFT_TOKEN_VAULT_API = "https://www.decimusdynamics.app/api/tokenvault?mint=";

export const NFT_TOKEN_VAULT_FILE_WALLET = "69fiF9NTmjHDVuyT3iTEydEHbwd9nPqmWxa4Jqk2NXpr";

export const STAKE_DATA_API = "https://www.decimusdynamics.app/api/stakeData";


export const SIGN_IN_API = "https://www.decimusdynamics.app/api/signin";


export const SIGN_OUT_API = "https://www.decimusdynamics.app/api/signout";

// for DC NFT 
export const DC_UPDATE_AUTHORITY = "FSHP7g2kz3Mhy4oQ3w8JYksPR487hMgkcrjYAdjzwtaE";

export const DC_VALID_FIRST_CREATOR = "";
